import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import HowThisWorkImageIcon from "../icons/howThisWorkImage";
const steps = [
    {
        id: 1,
        text: "After you fill out the form above, you will be connected with one of our best credit repair companies.",
    },
    {
        id: 2,
        text: "Once you are connected with the company, a representative will call you to ask you some questions about your credit situation, score, and history.",
    },
    {
        id: 3,
        text: "After that, you will be asked to choose a credit repair monthly plan! The company will take it from there and will follow up with you for any additional information they might need to help you.",
    },
    {
        id: 4,
        text: "Congratulations, you will now get your credit score repaired in no time!",
    },
];
export default function HowThisWork(): ReactElement {
    return (
        <div
            className={`flex ${styles["howThisWork"]} flex-col md:flex-row max-w-7xl mx-auto  px-4vw lg:px-4`}
        >
            <div className={`${styles["howThisWorkListWrapper"]}`}>
                <h2
                    className={`font-bold capitalize mb-12 text-3xl text-center md:text-left md:text-4xl ${styles["titleColor"]}`}
                >
                    How Credit Repair Works
                </h2>
                <div>
                    {steps.map((step) => (
                        <div
                            className={`flex justify-start items-center mb-12`}
                            key={step.id}
                        >
                            <div
                                className={`flex justify-center items-center font-bold ${styles["stepNum"]}`}
                            >
                                {step.id}
                            </div>
                            <div
                                className={`${styles["stepText"]} text-base md:text-xl`}
                            >
                                {step.text}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={` ${styles["howThisWorkImage"]}`}>
                <HowThisWorkImageIcon />
            </div>
        </div>
    );
}
